import React from "react";
import { Form } from "react-bootstrap";
import AtoDSelect from "./AtoDSelect";
import GoodBrokenMissingSelect from "./GoodBrokenMissingSelect";
import YesNoSelect from "./YesNoSelect";

const MiscComponentsForm = ({ miscComponents, isCreated }) => {
  return (
    <div className="container  mt-5  p-5 rounded-2 custom-boxshedow">
      <h5 className="text-center">Misc Components </h5>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className=" mb-3">
            {(miscComponents || isCreated) && (
              <AtoDSelect
                setValue={(val) => console.log(val)}
                label="Bridle Cable"
                name="misc_bridleCable"
                defaultValue={miscComponents?.bridleCable}
              />
            )}
          </div>
        </div>

        <div className="col-md-6 col-sm-12">
          <div className=" mb-3">
            {(miscComponents || isCreated) && (
              <AtoDSelect
                setValue={(val) => console.log(val)}
                label="Carrier Bar"
                name="misc_carrierBar"
                defaultValue={miscComponents?.carrierBar}
              />
            )}
          </div>
        </div>

        <div className="col-md-6 col-sm-12">
          <div className=" mb-3">
            {(miscComponents || isCreated) && (
              <>
                <label className="mb-2">Ladder</label>
                <Form.Select
                  defaultValue={miscComponents?.ladder}
                  name="misc_ladder"
                >
                  <option value="Good">Good</option>
                  <option value="Broken">Broken</option>
                  <option value="Missign">Missing</option>
                </Form.Select>
              </>
            )}
          </div>
        </div>

        <div className="col-md-6 col-sm-12">
          <div className=" mb-3">
            {(miscComponents || isCreated) && (
              <>
                <label className="mb-2">Belt Condition</label>
                <Form.Select
                  defaultValue={miscComponents?.beltCondition}
                  name="misc_beltCondition"
                >
                  <option value="Good">Good</option>
                  <option value="Worn">Worn</option>
                </Form.Select>
              </>
            )}
          </div>
        </div>

        <div className="col-md-6 col-sm-12">
          <div className=" mb-3">
            {(miscComponents || isCreated) && (
              <>
                <label className="mb-2">Base Condition</label>
                <Form.Select
                  defaultValue={miscComponents?.baseCondition}
                  name="misc_baseCondition"
                >
                  <option value="Good">Good</option>
                  <option value="Small Cracks">Small Cracks</option>
                  <option value="Needs Replacement">Needs Replacement</option>
                </Form.Select>
              </>
            )}
          </div>
        </div>

        <div className="col-md-6 col-sm-12">
          <div className=" mb-3">
            {(miscComponents || isCreated) && (
              <GoodBrokenMissingSelect
                setValue={(val) => console.log(val)}
                defaultValue={miscComponents?.tieDowns}
                name="misc_tieDowns"
                label="Tie Downs"
              />
            )}
          </div>
        </div>

        <div className="col-md-6 col-sm-12">
          <div className=" mb-3">
            {(miscComponents || isCreated) && (
              <AtoDSelect
                defaultValue={miscComponents?.guards}
                setValue={(val) => console.log(val)}
                name="misc_guards"
                label="Guards"
              />
            )}
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className=" mb-3">
            {(miscComponents || isCreated) && (
              <YesNoSelect
                defaultValue={miscComponents?.wellSigns}
                setValue={(val) => console.log(val)}
                name="misc_wellSigns"
                label="Well Signs"
              />
            )}
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className=" mb-3">
            {(miscComponents || isCreated) && (
              <YesNoSelect
                defaultValue={miscComponents?.rodRotatorWorking}
                setValue={(val) => console.log(val)}
                name="misc_rodRotatorWorking"
                label="Rod Rotator Working (if Applicable)"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiscComponentsForm;
